import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Template from '@clearscore/shared.website-template';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import type { ComponentProps } from 'react';
import Section from '@clearscore/shared.website-section';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import type TextAndImage from '@clearscore/shared.website-text-and-image';
import type ExplainerWithImage from '@clearscore/shared.website-explainer-with-image';
import type { ITwoPanelSection } from '@clearscore/shared.website-two-panel-section';
import type { TFunction } from 'react-i18next';
import type { IAppStoreConfig } from '@clearscore/shared.website-app-logos';

import styles from './loans-landing.module.css';
import { PAGE_NAMESPACE } from './lib/constants';
import marketsConfig from './lib/market-config';
import type { UseLoansTrackingReturn } from './hooks/use-loans-tracking';
import useLoansTracking from './hooks/use-loans-tracking';

export interface BenefitsPanelProps {
    heroImage: {
        image: IGatsbyImageData;
        alt?: string;
    };
    appStoreConfig?: IAppStoreConfig;
}

export type SectionConfigProps = Omit<LoansLandingProps, 'cookiePolicyConfig'> & { t: TFunction } & Pick<
        UseLoansTrackingReturn,
        'trackLandingPageSignUpClicked'
    >;

export interface LoansLandingProps {
    appStoreConfig: IAppStoreConfig;
    cookiePolicyConfig: {
        moreInfoLinkUrl: string;
        enabled: boolean;
        language: Record<string, string>;
    };
    twoPanelSection: Pick<ITwoPanelSection, 'images'> & {
        heroImage: {
            image: IGatsbyImageData;
            alt: string;
        };
    };
    logos: Array<{ alt: string; image: IGatsbyImageData }>;
    textBlock: {
        section: {
            image: { gatsbyImageData: IGatsbyImageData; description?: string };
            imageDesktop: { gatsbyImageData: IGatsbyImageData; description?: string };
        };
    };
    explainer: Pick<ComponentProps<typeof ExplainerWithImage>, 'image'>;
    whatsLoan: Pick<ComponentProps<typeof TextAndImage>, 'image'>;
    exampleLogos: Array<{ alt: string; image: IGatsbyImageData }>;
}

const LoansLanding = ({
    appStoreConfig,
    cookiePolicyConfig,
    twoPanelSection,
    logos,
    textBlock,
    explainer,
    whatsLoan,
    exampleLogos,
}: LoansLandingProps): React.ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const { sectionConfig } = useMarketConfig(marketsConfig);

    const { trackLandingPageSignUpClicked } = useLoansTracking();
    const sections = sectionConfig({
        appStoreConfig,
        twoPanelSection,
        logos,
        textBlock,
        explainer,
        whatsLoan,
        exampleLogos,
        t,
        trackLandingPageSignUpClicked,
    });
    return (
        <Template appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig} pullUpContent>
            <div className={cx(styles.fullWidth, styles.colorMidnight)}>
                {sections.map((item) => {
                    const { Component, id, props, section } = item;
                    if (props && 'childrenTop' in props) {
                        return <Component key={id} id={id} {...props} />;
                    }

                    return (
                        <Section key={id} id={id} {...section}>
                            <Component {...props} />
                        </Section>
                    );
                })}
            </div>
        </Template>
    );
};

export default LoansLanding;
