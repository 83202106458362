import i18next from 'i18next';
import { EN_GB, EN_ZA } from '@clearscore-group/lib.config.i18n';

import LoansLandingPage from './loans-landing';
import { PAGE_NAMESPACE } from './lib/constants';
import copyEnGb from './copy/pages/loans-landing-09-22/en-gb/copy.json';
import copyEnZa from './copy/pages/loans-landing-09-22/en-za/copy.json';

i18next.addResourceBundle(EN_GB, PAGE_NAMESPACE, copyEnGb);
i18next.addResourceBundle(EN_ZA, PAGE_NAMESPACE, copyEnZa);

export default LoansLandingPage;
