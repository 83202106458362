import type { ReactElement } from 'react';
import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Loans0922 from '@clearscore/website.loans-landing-09-22';
import { GB, ZA } from '@clearscore/config.i18n';

import avatar from '../assets/global/loans/loans-landingpage-250x250.png';
import Layout from '../components/layout';

const LoansTemplate = (
    props: PageProps<Queries.LoansTemplateSignupQueryVariables, { market: string }>,
): ReactElement => {
    const {
        data,
        location,
        pageContext: { market },
    } = props;

    const logos = [
        {
            alt: 'Admiral Logo',
            image: getImage(data.logoAdmiral),
        },
        {
            alt: '118 118 Money Logo',
            image: getImage(data.logo118118Money),
        },
        {
            alt: 'Halifax Logo',
            image: getImage(data.logoHalifax),
        },
        {
            alt: 'Post Office Logo',
            image: getImage(data.logoPostOffice),
        },
        {
            alt: 'Tesco Bank Logo',
            image: getImage(data.logoTescoBank),
        },
        {
            alt: 'Zopa Logo',
            image: getImage(data.logoZopa),
        },
    ];
    const marketTitle =
        // eslint-disable-next-line no-nested-ternary
        market === GB
            ? `Compare Loans | Find & Apply Online Today | ClearScore ${market.toUpperCase()}`
            : market === ZA
            ? `Personal Loans | Apply Online Today | ClearScore ${market.toUpperCase()}`
            : `Personal Loans | Compare and Apply Online | ClearScore ${market.toUpperCase()}`;
    return (
        <Layout
            meta={{
                title: marketTitle,
                description:
                    "Sign up for free and find personal loans, debt consolidation loans and reward guarantor loans tailored to your score. Checking won't harm your credit score.",
                image: avatar,
            }}
            location={location.pathname}
        >
            {({ commonModules }): ReactElement => (
                <Loans0922
                    logos={logos}
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    twoPanelSection={{
                        heroImage: {
                            image: getImage(data.heroImage0922),
                            alt: 'hero',
                        },
                        images: {
                            backgroundDesktop: { image: getImage(data.backgroundDesktop) },
                            backgroundTablet: { image: getImage(data.backgroundTablet) },
                            backgroundMobile: { image: getImage(data.backgroundMobile) },
                        },
                    }}
                    whatsLoan={{
                        image: { src: getImage(data.whatsLoan) },
                    }}
                    textBlock={{
                        section: {
                            theme: 'dark',
                            // @ts-expect-error fix after migration to TS
                            image: { gatsbyImage: getImage(data.textBlock) },
                            // @ts-expect-error fix after migration to TS
                            imageDesktop: { gatsbyImage: getImage(data.textBlockDesktop) },
                        },
                    }}
                    explainer={{
                        image: getImage(data.explainer),
                    }}
                    exampleLogos={[
                        { alt: 'Zopa', image: getImage(data.logoZopa) },
                        { alt: 'Likely Loans', image: getImage(data.logoLikelyLoans) },
                        { alt: 'Tesco Bank', image: getImage(data.logoTescoBank) },
                    ]}
                />
            )}
        </Layout>
    );
};

export const Head = () => <meta id="robots" name="robots" content="noindex" />;

export const query = graphql`
    query LoansTemplateSignup {
        heroImage: file(absolutePath: { regex: "/loans/loans_hero-image.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        loansInfoAppScreenshot: file(absolutePath: { regex: "/loans/loans-info-app-screenshot.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        janexHero: file(absolutePath: { regex: "/loans/janex.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        usb: file(absolutePath: { regex: "/loans/transfer-card.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        builder: file(absolutePath: { regex: "/loans/builder-card.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        controller: file(absolutePath: { regex: "/loans/purchase-card.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        guarantor: file(absolutePath: { regex: "za/loans/loans-guarantor.jpg/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        wing: file(absolutePath: { regex: "/loans/rewards-card.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        infoImageL: file(absolutePath: { regex: "/loans/TL-loans-landingpage-phone-L.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        infoImageS: file(absolutePath: { regex: "loans/TL-loans-landingpage-phone-S.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        howTo1: file(absolutePath: { regex: "/loans/howTo1.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        howTo2: file(absolutePath: { regex: "/loans/howTo2.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        howTo3: file(absolutePath: { regex: "/loans/howTo3.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        appStoreBadge: file(absolutePath: { regex: "/loans/app-store-badge.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        googlePlayBadge: file(absolutePath: { regex: "/loans/google-play-badge.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        howToGetALoanImage: file(absolutePath: { regex: "gb/loans/how-to-get-a-loan.jpg/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        clearsaverPanelMobile: file(absolutePath: { regex: "gb/loans/clearsaver_panel_mobile.jpg/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        clearsaverPanelDesktop: file(absolutePath: { regex: "gb/loans/clearsaver_panel_desktop.jpg/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }

        # loans 09 22 version
        logoAdmiral: file(absolutePath: { regex: "/loans-09-22/logos/admiral.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 75, layout: CONSTRAINED)
            }
        }
        logo118118Money: file(absolutePath: { regex: "/loans-09-22/logos/118-118-money.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 75, layout: CONSTRAINED)
            }
        }
        logoHalifax: file(absolutePath: { regex: "/loans-09-22/logos/halifax.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 75, layout: CONSTRAINED)
            }
        }
        logoPostOffice: file(absolutePath: { regex: "/loans-09-22/logos/post-office.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 75, layout: CONSTRAINED)
            }
        }
        logoTescoBank: file(absolutePath: { regex: "/loans-09-22/logos/tesco-bank.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 75, layout: CONSTRAINED)
            }
        }
        logoZopa: file(absolutePath: { regex: "/loans-09-22/logos/zopa.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 75, layout: CONSTRAINED)
            }
        }
        logoLikelyLoans: file(absolutePath: { regex: "/loans-09-22/logos/likely-loans.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 75, layout: CONSTRAINED)
            }
        }
        heroImage0922: file(absolutePath: { regex: "/loans-09-22/secondary_hero.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 40, breakpoints: [375, 1024, 1440], layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        backgroundMobile: file(absolutePath: { regex: "/loans-09-22/background-mobile.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 50, breakpoints: [375, 512], layout: FULL_WIDTH)
            }
        }
        backgroundTablet: file(absolutePath: { regex: "/loans-09-22/background-tablet.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 70, breakpoints: [768], layout: FULL_WIDTH)
            }
        }
        backgroundDesktop: file(absolutePath: { regex: "/loans-09-22/background-desktop.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 90, breakpoints: [1024, 1440], layout: FULL_WIDTH)
            }
        }
        whatsLoan: file(absolutePath: { regex: "/loans-09-22/whats-a-loan.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 50, layout: CONSTRAINED)
            }
        }
        textBlock: file(absolutePath: { regex: "/loans-09-22/free-text-block.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 50, breakpoints: [375, 768], layout: FULL_WIDTH)
            }
        }
        textBlockDesktop: file(absolutePath: { regex: "/loans-09-22/free-text-block-desktop.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 50, breakpoints: [1024, 1440], layout: FULL_WIDTH)
            }
        }
        explainer: file(absolutePath: { regex: "/loans-09-22/will-you-be-approved.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 50, layout: CONSTRAINED)
            }
        }
    }
`;

export default LoansTemplate;
