import type { ComponentProps, ReactElement, JSXElementConstructor } from 'react';
import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';
import LogoGrid from '@clearscore/shared.logo-grid';
import Link from '@clearscore/shared.website-link';
import Section from '@clearscore/shared.website-section';
import ThreeStep from '@clearscore/shared.three-step';
import TextAndImage from '@clearscore/shared.website-text-and-image';
import InfoSection from '@clearscore/shared.website-section-information-with-example';
import CardGrid from '@clearscore/shared.website-card-grid';
import Card from '@clearscore/shared.card-website-content';
import CardWebsiteCta from '@clearscore/shared.website-card-website-cta';
import TextBlock from '@clearscore/shared.website-free-text-block';
import ExplainerWithImage from '@clearscore/shared.website-explainer-with-image';
import FAQ from '@clearscore/shared.website-faq';
import TwoPanelSection from '@clearscore/shared.website-two-panel-section';
import LoanCalculator from '@clearscore/shared.website-loan-calculator';

import { CTA_NAME_FOR_TRACKING } from '../../hooks/use-loans-tracking';
import HeroPanel from '../../components/hero-panel';
import BenefitsPanel from '../../components/benefits-panel';
import { PreWrap } from '../utils';
import { ICONS } from '../constants';
import type { SectionConfigProps } from '../../loans-landing';

const config = {
    signupHref: 'https://app.clearscore.com/signup',
    explainerSection: {
        href: '/learn/loans/how-to-boost-your-chances-of-being-accepted-for-a-loan',
        cards: [
            {
                key: 'lenderCriteria',
                icon: ICONS.bankTick,
            },
            {
                key: 'incomeAndExpenses',
                icon: ICONS.coins,
            },
            {
                key: 'creditScore',
                icon: ICONS.report,
            },
        ],
    },
    typeOfLoans: [
        {
            key: 'personal',
            href: '/loans/personal',
        },
        {
            key: 'secured',
            href: '/loans/secured',
        },
        {
            key: 'guarantor',
            href: '/loans/guarantor-loans',
        },
        {
            key: 'carFinance',
            href: '/car-finance',
        },
        {
            key: 'debtConsolidation',
            href: '/loans/debt-consolidation',
        },
    ],
    steps: [
        {
            key: 'signUp',
            icon: ICONS.account,
        },
        {
            key: 'compare',
            icon: ICONS.loan,
        },
        {
            key: 'apply',
            icon: ICONS.bankTick,
        },
    ],
    cardGrid: {
        cards: [
            {
                key: 'debtConsolidation',
                icon: ICONS.creditCardAccent,
                href: '/loans/debt-consolidation',
            },
            {
                key: 'homeImprovements',
                icon: ICONS.houseAccent,
                href: '/learn/loans/a-handy-guide-to-home-improvement-loans',
            },
            {
                key: 'bigPurchases',
                icon: ICONS.carSportAccent,
                href: undefined,
            },
            {
                key: 'civilPartnerships',
                icon: ICONS.heartAccent,
                href: undefined,
            },
            {
                key: 'scoreBuilding',
                icon: ICONS.scoreChange,
                href: '/learn/credit-score-and-report/how-credit-builder-products-work-and-what-they-can-do-for-your-score',
            },
            {
                key: 'holidays',
                icon: ICONS.palmTreeAccent,
                href: undefined,
            },
        ],
    },
    faqs: [
        {
            name: 'canYouApply',
            href: '/loans/bad-credit',
        },
        { name: 'howMuch', href: undefined },
        { name: 'payingOffEarly', href: undefined },
        { name: 'universalCredit', href: '/loans/people-on-benefits' },
        {
            name: 'repayStruggle',
            href: 'https://www.citizensadvice.org.uk/debt-and-money/borrowing-money/types-of-borrowing/loans/',
        },
        { name: 'softHardCheck', href: undefined },
        { name: 'apr', href: undefined },
        { name: 'harmingScore', href: undefined },
        { name: 'csExclusives', href: undefined },
        { name: 'csPartners', href: undefined },
        {
            name: 'howCSWorks',
            href: 'https://help.clearscore.com/hc/en-us/articles/360019194599-How-does-ClearScore-make-money-',
        },
    ],
} as const;

export default {
    ...config,
    sectionConfig: (
        props: SectionConfigProps,
    ): Array<{
        Component: JSXElementConstructor<any>;
        id: string;
        section?: Omit<ComponentProps<typeof Section>, 'children'>;
        props?:
            | ComponentProps<typeof TwoPanelSection>
            | ComponentProps<typeof ThreeStep>
            | ComponentProps<typeof LogoGrid>
            | ComponentProps<typeof TextAndImage>
            | ComponentProps<typeof InfoSection>
            | ComponentProps<typeof CardGrid>
            | ComponentProps<typeof CardWebsiteCta>
            | ComponentProps<typeof TextBlock>
            | ComponentProps<typeof ExplainerWithImage>
            | ComponentProps<typeof FAQ>;
    }> => {
        const {
            twoPanelSection,
            logos,
            whatsLoan,
            exampleLogos,
            textBlock,
            explainer,
            t,
            trackLandingPageSignUpClicked,
        } = props;
        const { steps, cardGrid, typeOfLoans, explainerSection, faqs, signupHref } = config;

        const handleSignupCTA = (copy: string) => ({
            text: copy,
            href: signupHref,
            onClick: () =>
                trackLandingPageSignUpClicked({
                    signupCtaClicked: CTA_NAME_FOR_TRACKING,
                    signupCtaClickedCopy: copy,
                }),
        });

        return [
            {
                Component: TwoPanelSection,
                id: 'two-panel-section',
                props: {
                    images: twoPanelSection.images,
                    childrenTop: <HeroPanel signupHref={signupHref} />,
                    childrenBottom: <BenefitsPanel heroImage={twoPanelSection.heroImage} />,
                    backgroundDarkness: 30,
                },
            },
            {
                Component: LogoGrid,
                id: 'logo-grid',
                section: {
                    size: Section.Size.LARGE,
                },
                props: {
                    logos,
                    heading: t('logoGrid.heading') as string,
                },
            },
            {
                Component: ThreeStep,
                id: 'three-step-process',
                section: {
                    size: Section.Size.MEDIUM,
                    backgroundColor: Section.BackgroundColor.CHILL,
                    dataId: 'three-step',
                },
                props: {
                    heading: t('threeStepSection.heading'),
                    text: t('threeStepSection.description') as string,
                    steps: steps.map(
                        ({
                            key,
                            icon,
                        }: {
                            key: string;
                            icon: ReactElement;
                        }): {
                            description: string;
                            icon: ReactElement;
                            title: string;
                        } => ({
                            description: t(`threeStepSection.steps.${key}.description`),
                            icon,
                            title: t(`threeStepSection.steps.${key}.title`),
                        }),
                    ),
                    cta: handleSignupCTA(t('threeStepSection.cta.text')),
                },
            },
            {
                Component: TextAndImage,
                id: t('whatsALoan.id'),
                section: {
                    size: Section.Size.MEDIUM_LARGE,
                },
                props: {
                    text: [t('whatsALoan')],
                    image: whatsLoan.image,
                } as ComponentProps<typeof TextAndImage>,
            },
            {
                Component: InfoSection,
                id: 'compare-tailored-offers',
                section: {
                    size: Section.Size.MEDIUM_LARGE,
                    backgroundColor: 'light',
                },
                props: {
                    cardLanguage: {
                        exampleCardTitle: t('representativeExample.cardLanguage.exampleCardTitle'),
                        exampleCardSubtitle: t('representativeExample.cardLanguage.exampleCardSubtitle'),
                        exampleCardMonthlyText: t('representativeExample.cardLanguage.exampleCardMonthlyText'),
                        exampleCardMonthlyAmount: t('representativeExample.cardLanguage.exampleCardMonthlyAmount'),
                        exampleCardTotalText: t('representativeExample.cardLanguage.exampleCardTotalText'),
                        exampleCardTotalAmount: t('representativeExample.cardLanguage.exampleCardTotalAmount'),
                        exampleCardDisclaimers: [t('representativeExample.cardLanguage.exampleCardDisclaimers')],
                    },
                    firstSectionText: (
                        <PreWrap>
                            <Text tag={Text.tags.H3} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                                {t<string>('representativeExample.firstSectionText.heading')}
                            </Text>
                            <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.SMALL }}>
                                <Text.Body1>{t<string>('representativeExample.firstSectionText.body')}</Text.Body1>
                            </Spacer>
                            <Text.Caption>
                                {t<string>('representativeExample.firstSectionText.disclaimer')}
                            </Text.Caption>
                        </PreWrap>
                    ),
                    secondSectionText: (
                        <PreWrap>
                            <Text tag={Text.tags.H3} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                                {t<string>('representativeExample.secondSectionText.heading')}
                            </Text>
                            <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.SMALL }}>
                                <Text.Body1>
                                    {t<string>('representativeExample.secondSectionText.subheading')}
                                </Text.Body1>
                            </Spacer>
                        </PreWrap>
                    ),
                    cardDisplayState: 'default',
                    partnerIcon: exampleLogos[0],
                },
            },
            {
                Component: CardGrid,
                id: 'what-could-you-do-with-loan',
                props: {
                    heading: t('cardGrid.heading') as string,
                    subheading: t('cardGrid.subHeading') as string,
                    cardArray: (
                        <React.Fragment>
                            {cardGrid.cards.map(({ key, icon, href }) => (
                                <Card
                                    backgroundColor="chill"
                                    heading={t(`cardGrid.cards.${key}.heading`)}
                                    bodyText={t(`cardGrid.cards.${key}.bodyText`)}
                                    bodyHref={href}
                                    key={key}
                                    topIcon={icon}
                                />
                            ))}
                        </React.Fragment>
                    ),
                },
            },
            {
                Component: CardWebsiteCta,
                id: 'start-comparing',
                section: {
                    dataId: 'start-comparing',
                    backgroundColor: Section.BackgroundColor.LIGHT,
                    theme: 'dark',
                    size: Section.Size.SMALL,
                },
                props: {
                    heading: t('startComparing.heading') as string,
                    theme: 'dark',
                    cta: handleSignupCTA(t('startComparing.cta.text')),
                    alignment: CardWebsiteCta.Alignment.LEFT,
                },
            },
            {
                Component: LoanCalculator,
                id: 'calculator',
                section: {
                    dataId: 'loan-calculator',
                },
            },
            {
                Component: TextBlock,
                id: t('typesOfLoans.id'),
                section: {
                    theme: 'dark',
                    backgroundImage: (
                        <Section.BackgroundImage
                            image={textBlock.section.image}
                            imageDesktop={textBlock.section.imageDesktop}
                        />
                    ),
                },
                props: {
                    heading: t('typesOfLoans.heading') as string,
                    description: t('typesOfLoans.description'),
                    types: typeOfLoans.map(({ key, href }: { key: string; href: string }) => ({
                        href,
                        heading: t(`typesOfLoans.types.${key}.heading`),
                        description: t(`typesOfLoans.types.${key}.description`),
                    })),
                },
            },
            {
                Component: ExplainerWithImage,
                id: 'explainer-with-image',
                props: {
                    ...explainer,
                    ...(t('explainer') as Record<string, string>),
                    body2: {
                        text: t('explainer.body2.text'),
                        href: explainerSection.href,
                    },
                    cards: explainerSection.cards.map(({ key, icon }: { key: string; icon: ReactElement }) => ({
                        ...(t(`explainer.cards.${key}`) as unknown as ComponentProps<typeof Card>),
                        cardType: Card.CardType.FLAT,
                        topIcon: icon,
                        backgroundColor: 'chill',
                    })),
                },
            },
            {
                Component: InfoSection,
                id: 'can-you-get-loan',
                section: {
                    size: Section.Size.MEDIUM_LARGE,
                    backgroundColor: Section.BackgroundColor.LIGHT,
                },
                props: {
                    cardLanguage: {
                        exampleCardMonthlyAmount: t('canYouGetLoan.cardLanguage.exampleCardMonthlyAmount'),
                        exampleCardMonthlyText: t('canYouGetLoan.cardLanguage.exampleCardMonthlyText'),
                        exampleCardSubtitle: t('canYouGetLoan.cardLanguage.exampleCardSubtitle'),
                        exampleCardTitle: t('canYouGetLoan.cardLanguage.exampleCardTitle'),
                        exampleCardTotalAmount: t('canYouGetLoan.cardLanguage.exampleCardTotalAmount'),
                        exampleCardTotalText: t('canYouGetLoan.cardLanguage.exampleCardTotalText'),
                        exampleCardDisclaimers: [t('canYouGetLoan.cardLanguage.exampleCardDisclaimers')],
                    },
                    firstSectionText: (
                        <PreWrap>
                            <Text tag={Text.tags.H2} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                                {t<string>('canYouGetLoan.firstSectionText.heading')}
                            </Text>
                            <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.SMALL }}>
                                <Text tag={Text.tags.H3} weight={Text.weights.BOLD} type={Text.types.MEDIUM}>
                                    <div>{t<string>('canYouGetLoan.firstSectionText.subHeading')}</div>
                                </Text>
                            </Spacer>
                            <Spacer all={{ bottom: Spacer.spacings.SMALL }}>
                                <Text.Body1>{t<string>('canYouGetLoan.firstSectionText.body1')}</Text.Body1>
                            </Spacer>
                            <Spacer all={{ bottom: Spacer.spacings.SMALL }}>
                                <Text.Body1>{t<string>('canYouGetLoan.firstSectionText.body2')}</Text.Body1>
                            </Spacer>
                            <Text.Body1>
                                {t<string>('canYouGetLoan.firstSectionText.body3')}
                                <Link href={t('canYouGetLoan.firstSectionText.badCreditHref')} isSimpleLink>
                                    {t<string>('canYouGetLoan.firstSectionText.badCreditLinkText')}
                                </Link>
                            </Text.Body1>
                        </PreWrap>
                    ),
                    secondSectionText: (
                        <PreWrap>
                            <Spacer all={{ bottom: Spacer.spacings.SMALL }}>
                                <Text.Body1>{t<string>('canYouGetLoan.secondSectionText.body')}</Text.Body1>
                            </Spacer>
                        </PreWrap>
                    ),
                    partnerIcon: exampleLogos[1],
                },
            },
            {
                Component: InfoSection,
                id: 'lenders-offers',
                section: {
                    size: Section.Size.MEDIUM_LARGE,
                },
                props: {
                    cardLanguage: {
                        exampleCardMonthlyAmount: t('lendersOffer.cardLanguage.exampleCardMonthlyAmount'),
                        exampleCardMonthlyText: t('lendersOffer.cardLanguage.exampleCardMonthlyText'),
                        exampleCardSubtitle: t('lendersOffer.cardLanguage.exampleCardSubtitle'),
                        exampleCardTitle: t('lendersOffer.cardLanguage.exampleCardTitle'),
                        exampleCardTotalAmount: t('lendersOffer.cardLanguage.exampleCardTotalAmount'),
                        exampleCardTotalText: t('lendersOffer.cardLanguage.exampleCardTotalText'),
                        exampleCardDisclaimers: [t('lendersOffer.cardLanguage.exampleCardDisclaimers')],
                    },
                    firstSectionText: (
                        <PreWrap>
                            <Spacer all={{ bottom: Spacer.spacings.MEDIUM }}>
                                <Text tag={Text.tags.H2} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                                    {t<string>('lendersOffer.firstSectionText.heading')}
                                </Text>
                            </Spacer>
                            <Text.Body1>{t<string>('lendersOffer.firstSectionText.body')}</Text.Body1>
                        </PreWrap>
                    ),
                    secondSectionText: (
                        <PreWrap>
                            <Text tag={Text.tags.H2} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                                {t<string>('lendersOffer.secondSectionText.heading')}
                            </Text>
                            <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.SMALL }}>
                                <Text.Body1>{t<string>('lendersOffer.secondSectionText.body')}</Text.Body1>
                            </Spacer>
                        </PreWrap>
                    ),
                    partnerIcon: exampleLogos[2],
                },
            },
            {
                Component: CardWebsiteCta,
                id: 'find-the-right-loan',
                section: {
                    dataId: 'find-the-right-loan',
                    backgroundColor: Section.BackgroundColor.LIGHT,
                    theme: 'dark',
                    size: Section.Size.SMALL,
                },
                props: {
                    heading: t('findTheRightLoan.heading') as string,
                    bodyText: t('findTheRightLoan.bodyText'),
                    cta: handleSignupCTA(t('findTheRightLoan.cta.text')),
                    alignment: CardWebsiteCta.Alignment.LEFT,
                    theme: 'dark',
                },
            },
            {
                Component: FAQ,
                id: 'frequently-asked-questions',
                section: {
                    size: Section.Size.MEDIUM_LARGE,
                    dataId: 'faq',
                },
                props: {
                    heading: t('faq.heading'),
                    description: t('faq.description'),
                    faqs: faqs.map(({ name, href }) => ({
                        question: t(`faq.faqs.${name}.question`),
                        answer: [t(`faq.faqs.${name}.answer`)],
                        href,
                        dataId: name,
                    })),
                    cta: handleSignupCTA(t('faq.cta.text')),
                },
            },
        ];
    },
};
