import type { ReactElement } from 'react';
import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import BottomlessHero from '@clearscore/shared.website-bottomless-hero';
import Stack from '@clearscore/ui.rainbow.stack';
import Benefits from '@clearscore/shared.website-benefits';
import TrustPilot from '@clearscore/shared.website-trustpilot';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import { useTranslation } from 'react-i18next';
import AppStoreLogos from '@clearscore/shared.website-app-logos';

import styles from './benefits-panel.module.css';
import marketConfig from './lib/market-config';
import { PAGE_NAMESPACE } from '../../lib/constants';
import type { BenefitsPanelProps } from '../../loans-landing';

const BenefitsPanel = ({ heroImage, appStoreConfig }: BenefitsPanelProps): ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const { benefits, callouts, widgets } = useMarketConfig(marketConfig);
    const { trustPilot, appStoreBadges } = widgets;
    return (
        <BottomlessHero imageAlt={heroImage.alt ?? ''} image={heroImage.image}>
            <Stack all={Stack.spacings.BIG}>
                {trustPilot ? (
                    <div className={styles.trustPilotContainer}>
                        <TrustPilot theme={TrustPilot.themes.DARK} />
                    </div>
                ) : undefined}
                {appStoreConfig && appStoreBadges ? (
                    <div className={styles.appStoreLogosContainer}>
                        <AppStoreLogos panel={{ type: 'hero' }} appStoreConfig={appStoreConfig || undefined} />
                    </div>
                ) : undefined}
                <Benefits
                    benefits={benefits.map(({ key, icon }: { key: string; icon: ReactElement }) => ({
                        icon,
                        text: t(`twoPanelSection.benefits.${key}`),
                    }))}
                />
                <Stack all={Stack.spacings.MEDIUM}>
                    {callouts.map(({ key, links }) => (
                        <Text.Body1 key={key}>
                            {interpolateWithComponents(
                                t(`twoPanelSection.callouts.${key}`),
                                links.map((link) => ({
                                    Component: ({ children }: { children: string }) => (
                                        <Text.Link href={link} theme={Text.Link.themes.DARK}>
                                            <Text.Strong>{children}</Text.Strong>
                                        </Text.Link>
                                    ),
                                })),
                            )}
                        </Text.Body1>
                    ))}
                </Stack>
            </Stack>
        </BottomlessHero>
    );
};
export default BenefitsPanel;
