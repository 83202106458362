import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import Stack from '@clearscore/ui.rainbow.stack';
import Spacer from '@clearscore/ui.rainbow.spacer';
import BoldLink from '@clearscore/shared.website-bold-link';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';
import ContentfulRichTextRenderer from '@clearscore/shared.website-contentful-rich-text-renderer';
import AnalyticsProvider from '@clearscore/shared.website-analytics-provider';

import styles from './free-text-block.module.css';

export interface IType {
    heading: string;
    description: string;
    href?: string;
}

const Types = ({
    types,
    theme,
}: {
    types: Array<IType>;
    theme: (typeof BoldLink.themes)[keyof typeof BoldLink.themes];
}): React.ReactElement => (
    <div className={styles.types}>
        {types.map(({ heading, description, href }) => (
            <Stack all={Stack.spacings.TINY} key={heading}>
                <Text tag={Text.tags.H3} weight={Text.weights.BOLD} type={Text.types.MEDIUM}>
                    {heading}
                </Text>
                <Text.Body1>
                    {interpolateWithComponents(description, [{ Component: BoldLink, props: { href, theme } }])}
                </Text.Body1>
            </Stack>
        ))}
    </div>
);

export interface IFreeTextBlockRegularProps {
    heading: string;
    description?: string;
    bottomDescription?: string;
    types: Array<IType>;
    theme?: 'light' | 'dark';
}

export interface IFreeTextBlockContentfulProps {
    text: { raw: string };
    // eslint-disable-next-line camelcase
    contentful_id: string;
    sys: {
        revision: number;
    };
}

export type IFreeTextBlockProps = IFreeTextBlockRegularProps | IFreeTextBlockContentfulProps;

export const FreeTextBlock = ({ ...props }: IFreeTextBlockProps): React.ReactElement => {
    if ('text' in props) {
        return (
            <AnalyticsProvider
                trackingData={{
                    entry_id: props.contentful_id,
                    entry_version: props.sys.revision,
                }}
            >
                <ContentfulRichTextRenderer text={props.text} />{' '}
            </AnalyticsProvider>
        );
    }

    return (
        <React.Fragment>
            <Text tag={Text.tags.H2} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                {props.heading}
            </Text>
            {props.description ? (
                <Spacer all={{ top: Spacer.spacings.SMALL }}>
                    <Text.Body1>{props.description}</Text.Body1>
                </Spacer>
            ) : null}
            <Spacer all={{ top: Spacer.spacings.LARGE }}>
                <Types
                    types={props.types}
                    theme={(props.theme ?? 'dark') === 'dark' ? BoldLink.themes.DARK : BoldLink.themes.LIGHT}
                />
            </Spacer>
            {props.bottomDescription ? (
                <Spacer all={{ top: Spacer.spacings.SMALL }}>
                    <Text.Body1>{props.bottomDescription}</Text.Body1>
                </Spacer>
            ) : null}
        </React.Fragment>
    );
};

export default FreeTextBlock;
