import useWebsiteTracking from '@clearscore-group/lib.hooks.use-website-tracking';

export const URL_TRACKING_EVENT = '/loans';
export const TRACKING_EVENT_SIGN_UP = 'fe_landing_page_signup_clicked';
export const EMAIL_SUBMITTED_NAME_FOR_TRACKING = 'fe_loans_landing_email_submitted';
export const CTA_NAME_FOR_TRACKING = 'primary_cta';

interface TrackLandingPageSignUpClickedProps {
    signupCtaClicked: string;
    signupCtaClickedCopy: string;
    href?: string;
}

interface TrackEmailSubmittedProps {
    signUpEvent: string;
    sectionHeaderCopy: string;
    sectionBodyCopy: string;
    moduleType: string;
    ctaCopy: string;
    isEmailSubmitSuccessful: boolean;
    ctaUrl: string;
}

export interface UseLoansTrackingReturn {
    trackLandingPageSignUpClicked(props: TrackLandingPageSignUpClickedProps): void;
    trackEmailSignupAttempt(props: TrackEmailSubmittedProps): void;
}

const useLoansTracking = (): UseLoansTrackingReturn => {
    const track = useWebsiteTracking();

    return {
        trackEmailSignupAttempt: ({
            signUpEvent,
            sectionHeaderCopy,
            sectionBodyCopy,
            moduleType,
            ctaCopy,
            isEmailSubmitSuccessful,
            ctaUrl,
        }) =>
            track({
                name: signUpEvent,
                props: {
                    e: signUpEvent,
                    section_header_copy: sectionHeaderCopy,
                    section_body_copy: sectionBodyCopy,
                    module_type: moduleType,
                    email_submit_successful: isEmailSubmitSuccessful.toString(),
                    cta_copy: ctaCopy,
                    cta_url: ctaUrl,
                },
            }),
        trackLandingPageSignUpClicked: (props: TrackLandingPageSignUpClickedProps): void =>
            track({
                name: TRACKING_EVENT_SIGN_UP,
                props: {
                    landing_page_url: URL_TRACKING_EVENT,
                    signup_cta_clicked: props.signupCtaClicked,
                    signup_cta_clicked_copy: props.signupCtaClickedCopy,
                },
            }),
    };
};

export default useLoansTracking;
