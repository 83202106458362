import type { ReactElement } from 'react';
import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import { useTranslation } from 'react-i18next';
import Spacer from '@clearscore/ui.rainbow.spacer';
import CreateProspectForm from '@clearscore/shared.website-create-prospect-form';
import { useCreateProspect } from '@clearscore/shared.website-hooks';

import styles from './hero-panel.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';
import useLoansTracking, { EMAIL_SUBMITTED_NAME_FOR_TRACKING } from '../../hooks/use-loans-tracking';

const HeroPanel = ({ signupHref }: { signupHref: string }): ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const { isLoading, handleSubmit } = useCreateProspect();
    const { trackEmailSignupAttempt } = useLoansTracking();

    return (
        <React.Fragment>
            <Text tag={Text.tags.H1} type={Text.types.HUGE} weight={Text.weights.BOOK}>
                {t('twoPanelSection.heading')}
            </Text>
            <Spacer
                all={{ top: Spacer.spacings.LARGE, bottom: Spacer.spacings.SUPER }}
                large={{ bottom: Spacer.spacings.HUGE }}
            >
                <Text tag={Text.tags.H2} weight={Text.weights.BOLD} type={Text.types.MEDIUM}>
                    {t('twoPanelSection.subHeading')}
                </Text>
            </Spacer>
            <div className={styles.createProspectForm}>
                <CreateProspectForm
                    theme={CreateProspectForm.themes.DARK}
                    language={{
                        submitCta: t('twoPanelSection.cta'),
                    }}
                    isLightBg
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    onClickSubmit={(isValid) =>
                        trackEmailSignupAttempt({
                            signUpEvent: EMAIL_SUBMITTED_NAME_FOR_TRACKING,
                            sectionHeaderCopy: t('twoPanelSection.heading'),
                            sectionBodyCopy: t('twoPanelSection.subHeading'),
                            moduleType: 'Hero',
                            ctaCopy: t('twoPanelSection.cta'),
                            isEmailSubmitSuccessful: isValid,
                            ctaUrl: signupHref,
                        })
                    }
                />
            </div>
            <Spacer all={{ top: Spacer.spacings.BIG }} large={{ top: Spacer.spacings.LARGE }}>
                <Text.Body2>{t('twoPanelSection.disclaimer')}</Text.Body2>
            </Spacer>
        </React.Fragment>
    );
};
export default HeroPanel;
