import { GB, ZA } from '@clearscore-group/lib.config.i18n';
import React from 'react';
import SuccessTickIcon from '@clearscore/rainbow.icons.success-tick';

export default {
    [GB]: {
        benefits: [
            {
                key: 'compareLoans',
                icon: <SuccessTickIcon />,
            },
            {
                key: 'personalisedOffers',
                icon: <SuccessTickIcon />,
            },
            {
                key: 'scoreAndReport',
                icon: <SuccessTickIcon />,
            },
        ],
        callouts: [
            {
                key: 'signUp',
                links: ['https://app.clearscore.com/signup'],
            },
            {
                key: 'keepReading',
                links: ['#loan-basics', '#loan-types'],
            },
        ],
        widgets: {
            trustPilot: true,
            appStoreBadges: false,
        },
    },
    [ZA]: {
        benefits: [
            {
                key: 'compareLoans',
                icon: <SuccessTickIcon />,
            },
            {
                key: 'personalisedOffers',
                icon: <SuccessTickIcon />,
            },
            {
                key: 'scoreAndReport',
                icon: <SuccessTickIcon />,
            },
        ],
        callouts: [
            {
                key: 'signUp',
                links: ['https://app.clearscore.com/signup'],
            },
            {
                key: 'keepReading',
                links: ['#loan-basics', '#loan-types'],
            },
        ],
        widgets: {
            trustPilot: false,
            appStoreBadges: true,
        },
    },
} as const;
