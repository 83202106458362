import React from 'react';
import Card from '@clearscore/ui.rainbow.card';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Text from '@clearscore/ui.rainbow.text';
import Stack from '@clearscore/ui.rainbow.stack';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import cx from 'classnames';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';

import styles from './card-representative-example.module.css';

export interface ICardRepresentativeExampleProps {
    title: string;
    partnerIcon?: { alt: string; image: IGatsbyImageData };
    description: string;
    monthlyPrice: string;
    monthlyPriceDescription: string;
    totalPrice: string;
    totalPriceDescription: string;
    disclaimers?: string[];
    cardDisplayState?: (typeof Card.DisplayState)[keyof typeof Card.DisplayState];
}

export const CardRepresentativeExample = ({
    title,
    description,
    monthlyPrice,
    monthlyPriceDescription,
    totalPrice,
    totalPriceDescription,
    disclaimers = [],
    partnerIcon,
    cardDisplayState,
}: ICardRepresentativeExampleProps): React.ReactElement => (
    <Card>
        <Card.ContentContainer>
            <Card.ContentPrimary displayState={cardDisplayState}>
                <Stack all={Stack.spacings.MEDIUM}>
                    <div className={styles.header}>
                        <Text tag={Text.tags.H3} weight={Text.weights.BOLD} type={Text.types.MEDIUM}>
                            {title}
                        </Text>
                        {partnerIcon ? (
                            <Spacer all={{ left: Spacer.spacings.MEDIUM }}>
                                <div className={styles.partnerIcon}>
                                    <GatsbyImage
                                        className={styles.partnerIcon}
                                        alt={partnerIcon.alt}
                                        image={partnerIcon.image}
                                    />
                                </div>
                            </Spacer>
                        ) : null}
                    </div>
                    <Text.Body1>{description}</Text.Body1>
                    <div className={cx(styles.prices, cardDisplayState && styles[cardDisplayState])}>
                        <div>
                            <span className={styles.price}>
                                <Text.H3 tag={Text.tags.P}>
                                    {interpolateWithComponents(monthlyPrice, [
                                        { Component: Text.Body2, props: { tag: Text.tags.SPAN } },
                                    ])}
                                </Text.H3>
                            </span>
                            <Text.Caption>{monthlyPriceDescription}</Text.Caption>
                        </div>
                        <div>
                            <span className={styles.price}>
                                <Text.H3 tag={Text.tags.P}>
                                    {interpolateWithComponents(totalPrice, [
                                        { Component: Text.Body2, props: { tag: Text.tags.SPAN } },
                                    ])}
                                </Text.H3>
                            </span>
                            <Text.Caption>{totalPriceDescription}</Text.Caption>
                        </div>
                    </div>

                    <Stack all={Stack.spacings.MICRO}>
                        {disclaimers.map((disclaimer) => (
                            <Text.Caption key={disclaimer}>{disclaimer}</Text.Caption>
                        ))}
                    </Stack>
                </Stack>
            </Card.ContentPrimary>
        </Card.ContentContainer>
    </Card>
);

CardRepresentativeExample.CardDisplayState = Card.DisplayState;
export default CardRepresentativeExample;
