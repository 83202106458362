import type { ComponentProps } from 'react';
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import CardWebsiteContent from '@clearscore/shared.card-website-content';
import Text from '@clearscore/ui.rainbow.text';
import Stack from '@clearscore/ui.rainbow.stack';
import Spacer from '@clearscore/ui.rainbow.spacer';
import ContentfulComponent from '@clearscore/shared.website-contentful-component';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';
import ContentfulRichTextRenderer from '@clearscore/shared.website-contentful-rich-text-renderer';
import type { IRichTextRendererOptions } from '@clearscore/shared.website-contentful-rich-text-renderer';
import BoldLink from '@clearscore/shared.website-bold-link';
import AnalyticsProvider from '@clearscore/shared.website-analytics-provider';

import styles from './explainer-with-image.module.css';

export interface IExplainerWithImageProps {
    heading: string;
    image: IGatsbyImageData;
    alt: string;
    body1: { text: string; href?: string };
    cards: Array<ComponentProps<typeof CardWebsiteContent>>;
    body2: { text: string; href?: string };
    dataId?: string;
}

export interface IExplainerWithImageContentfulProps {
    image: {
        gatsbyImageData: IGatsbyImageData;
        description?: string;
    };
    text: {
        raw: string;
        references: Array<
            {
                __typename: string;
                // eslint-disable-next-line camelcase
                contentful_id: string;
            } & ComponentProps<typeof CardWebsiteContent>
        >;
    };
    // eslint-disable-next-line camelcase
    contentful_id: string;
    sys: {
        revision: number;
    };
}

const componentMap = {
    ContentCard: (props: ComponentProps<typeof CardWebsiteContent>) => (
        <div className={styles.cardContainer}>
            <CardWebsiteContent {...props} />
        </div>
    ),
};

const getRichTextRenderOverrides = (
    embedReferences: IExplainerWithImageContentfulProps['text']['references'],
): Partial<IRichTextRendererOptions> => ({
    renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            const embedReference = embedReferences.find((ref) => ref.contentful_id === node.data.target.sys.id);
            return embedReference ? <ContentfulComponent {...embedReference} componentMap={componentMap} /> : null;
        },
    },
});

export const ExplainerWithImage = (
    props: IExplainerWithImageProps | IExplainerWithImageContentfulProps,
): React.ReactElement => (
    <div className={styles.container}>
        {'gatsbyImageData' in props.image ? (
            <GatsbyImage
                alt={props.image.description ?? ''}
                image={props.image.gatsbyImageData}
                className={styles.image}
                imgClassName={styles.gatsbyImage}
                objectPosition="top"
            />
        ) : (
            <GatsbyImage
                alt={(props as IExplainerWithImageProps).alt ?? ''}
                image={props.image}
                className={styles.image}
                imgClassName={styles.gatsbyImage}
                objectPosition="top"
            />
        )}
        <div className={styles.text}>
            {'text' in props ? (
                <AnalyticsProvider
                    trackingData={{
                        entry_id: props.contentful_id,
                        entry_version: props.sys.revision,
                    }}
                >
                    <ContentfulRichTextRenderer
                        text={props.text}
                        renderOverrides={getRichTextRenderOverrides(props.text.references)}
                    />
                </AnalyticsProvider>
            ) : (
                <React.Fragment>
                    <Spacer all={{ bottom: Spacer.spacings.SMALL }}>
                        <Text tag={Text.tags.H2} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                            {props.heading}
                        </Text>
                    </Spacer>
                    <Text.Body1 dataId="explainer-body-1">
                        {interpolateWithComponents(props.body1.text, [
                            {
                                Component: BoldLink,
                                props: { href: props.body1.href },
                            },
                        ])}
                    </Text.Body1>
                    <Spacer all={{ vertical: Spacer.spacings.LARGE }}>
                        <Stack all={Stack.spacings.BIG}>
                            {props.cards.map((card, index) => (
                                <CardWebsiteContent key={index} {...card} />
                            ))}
                        </Stack>
                    </Spacer>
                    <Text.Body1 dataId="explainer-body-2">
                        {interpolateWithComponents(props.body2.text, [
                            {
                                Component: BoldLink,
                                props: { href: props.body2.href },
                            },
                        ])}
                    </Text.Body1>
                </React.Fragment>
            )}
        </div>
    </div>
);

export default ExplainerWithImage;
