import type { ComponentProps, ReactElement, JSXElementConstructor } from 'react';
import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';
import LogoGrid from '@clearscore/shared.logo-grid';
import ThreeStep from '@clearscore/shared.three-step';
import Section from '@clearscore/shared.website-section';
import TextAndImage from '@clearscore/shared.website-text-and-image';
import InfoSection from '@clearscore/shared.website-section-information-with-example';
import CardGrid from '@clearscore/shared.website-card-grid';
import Card from '@clearscore/shared.card-website-content';
import CardWebsiteCta from '@clearscore/shared.website-card-website-cta';
import TextBlock from '@clearscore/shared.website-free-text-block';
import ExplainerWithImage from '@clearscore/shared.website-explainer-with-image';
import FAQ from '@clearscore/shared.website-faq';
import TwoPanelSection from '@clearscore/shared.website-two-panel-section';

import { CTA_NAME_FOR_TRACKING } from '../../hooks/use-loans-tracking';
import HeroPanel from '../../components/hero-panel';
import BenefitsPanel from '../../components/benefits-panel';
import { PreWrap } from '../utils';
import { ICONS } from '../constants';
import type { SectionConfigProps } from '../../loans-landing';

const config = {
    signupHref: 'https://app.clearscore.com/signup',
    explainerSection: {
        href: '/learn/loans/how-to-boost-your-chances-of-being-accepted-for-a-loan',
        cards: [
            {
                key: 'lenderCriteria',
                icon: ICONS.bankTick,
            },
            {
                key: 'incomeAndExpenses',
                icon: ICONS.coins,
            },
            {
                key: 'creditScore',
                icon: ICONS.report,
            },
        ],
    },
    typesOfLoans: [
        {
            key: 'personal',
            href: 'https://www.clearscore.com/learn/loans/what-is-a-personal-loan',
        },
        {
            key: 'guarantor',
            href: 'https://www.clearscore.com/learn/loans/everything-you-need-to-know-about-the-guarantor-loan',
        },
        {
            key: 'badCredit',
            href: 'https://www.clearscore.com/learn/loans/secured-vs-unsecured-loans-which-ones-right-for-you',
        },
        {
            key: 'secured',
            href: 'https://www.clearscore.com/learn/loans/secured-vs-unsecured-loans-which-ones-right-for-you',
        },
        {
            key: 'carFinance',
            href: 'https://www.clearscore.com/car-finance',
        },
        {
            key: 'debtConsolidation',
            href: 'https://www.clearscore.com/learn/managing-money/a-guide-to-debt-consolidation-loans',
        },
    ],
    steps: [
        {
            key: 'signUp',
            icon: ICONS.account,
        },
        {
            key: 'compare',
            icon: ICONS.loan,
        },
        {
            key: 'apply',
            icon: ICONS.bankTick,
        },
    ],
    cardGrid: {
        cards: [
            {
                key: 'debtConsolidation',
                icon: ICONS.creditCardMultiple,
                href: '/learn/managing-money/a-guide-to-debt-consolidation-loans',
            },
            {
                key: 'homeImprovements',
                icon: ICONS.house,
                href: '/learn/loans/a-handy-guide-to-home-improvement-loans',
            },
            {
                key: 'bigPurchases',
                icon: ICONS.carSport,
                href: undefined,
            },
            {
                key: 'civilPartnerships',
                icon: ICONS.heart,
                href: undefined,
            },
            {
                key: 'scoreBuilding',
                icon: ICONS.scoreChangeSmall,
                href: '/learn/credit-score-and-report/how-credit-builder-products-work-and-what-they-can-do-for-your-score',
            },
            {
                key: 'schoolExpenses',
                href: '/learn/credit-score-and-report/how-credit-builder-products-work-and-what-they-can-do-for-your-score',
                icon: ICONS.bookOpen,
            },
        ],
    },
    faqs: [
        { name: 'howMuch', href: undefined },
        { name: 'payingOffEarly', href: undefined },
        { name: 'unemployedAndBenefits', href: undefined },
        {
            name: 'repayStruggle',
            href: 'https://www.fsca.co.za/Pages/Default.aspx',
        },
        { name: 'softHardCheck', href: undefined },
        { name: 'harmingScore', href: undefined },
        { name: 'csExclusives', href: undefined },
        { name: 'csPartners', href: undefined },
        {
            name: 'howCSWorks',
            href: 'https://help.clearscore.com/hc/en-us/articles/360019194599-How-does-ClearScore-make-money-',
        },
    ],
} as const;

export default {
    ...config,
    sectionConfig: (
        props: SectionConfigProps,
    ): Array<{
        Component: JSXElementConstructor<any>;
        id: string;
        section?: Omit<ComponentProps<typeof Section>, 'children'>;
        props?:
            | ComponentProps<typeof TwoPanelSection>
            | ComponentProps<typeof ThreeStep>
            | ComponentProps<typeof LogoGrid>
            | ComponentProps<typeof TextAndImage>
            | ComponentProps<typeof InfoSection>
            | ComponentProps<typeof CardGrid>
            | ComponentProps<typeof CardWebsiteCta>
            | ComponentProps<typeof TextBlock>
            | ComponentProps<typeof ExplainerWithImage>
            | ComponentProps<typeof FAQ>;
    }> => {
        const {
            appStoreConfig,
            twoPanelSection,
            logos,
            whatsLoan,
            exampleLogos,
            explainer,
            t,
            trackLandingPageSignUpClicked,
        } = props;
        const { steps, cardGrid, typesOfLoans, explainerSection, faqs, signupHref } = config;

        const handleSignupCTA = (copy: string) => ({
            text: copy,
            href: signupHref,
            onClick: () =>
                trackLandingPageSignUpClicked({
                    signupCtaClicked: CTA_NAME_FOR_TRACKING,
                    signupCtaClickedCopy: copy,
                }),
        });

        return [
            {
                Component: TwoPanelSection,
                id: 'two-panel-section',
                props: {
                    images: twoPanelSection.images,
                    childrenTop: <HeroPanel signupHref={signupHref} />,
                    childrenBottom: (
                        <BenefitsPanel
                            heroImage={twoPanelSection.heroImage}
                            appStoreConfig={appStoreConfig || undefined}
                        />
                    ),
                    backgroundDarkness: 30,
                },
            },
            {
                Component: ThreeStep,
                id: 'three-step-process',
                section: {
                    size: Section.Size.HUGE,
                    dataId: 'three-step',
                },
                props: {
                    heading: t('threeStepSection.heading'),
                    text: t('threeStepSection.description') as string,
                    steps: steps.map(
                        ({
                            key,
                            icon,
                        }: {
                            key: string;
                            icon: ReactElement;
                        }): {
                            description: string;
                            icon: ReactElement;
                            title: string;
                        } => ({
                            description: t(`threeStepSection.steps.${key}.description`),
                            icon,
                            title: t(`threeStepSection.steps.${key}.title`),
                        }),
                    ),
                    cta: handleSignupCTA(t('threeStepSection.cta.text')),
                },
            },
            {
                Component: LogoGrid,
                id: 'logo-grid',
                props: {
                    logos,
                    heading: t('logoGrid.heading') as string,
                },
            },
            {
                Component: TextAndImage,
                id: 'loan-basics',
                props: {
                    text: [t('whatsALoan')],
                    image: whatsLoan.image,
                    isImageFirst: true,
                } as ComponentProps<typeof TextAndImage>,
            },
            {
                Component: InfoSection,
                id: 'compare-tailored-offers',
                section: {
                    backgroundColor: Section.BackgroundColor.LIGHT,
                },
                props: {
                    cardLanguage: {
                        exampleCardTitle: t('representativeExample.cardLanguage.exampleCardTitle'),
                        exampleCardSubtitle: t('representativeExample.cardLanguage.exampleCardSubtitle'),
                        exampleCardMonthlyText: t('representativeExample.cardLanguage.exampleCardMonthlyText'),
                        exampleCardMonthlyAmount: t('representativeExample.cardLanguage.exampleCardMonthlyAmount'),
                        exampleCardTotalText: t('representativeExample.cardLanguage.exampleCardTotalText'),
                        exampleCardTotalAmount: t('representativeExample.cardLanguage.exampleCardTotalAmount'),
                        exampleCardDisclaimers: [t('representativeExample.cardLanguage.exampleCardDisclaimers')],
                    },
                    firstSectionText: (
                        <PreWrap>
                            <Text tag={Text.tags.H3} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                                {t('representativeExample.firstSectionText.heading')}
                            </Text>
                            <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.SMALL }}>
                                <Text.Body1>{t('representativeExample.firstSectionText.body')}</Text.Body1>
                            </Spacer>
                            <Text.Caption>{t('representativeExample.firstSectionText.disclaimer')}</Text.Caption>
                        </PreWrap>
                    ),
                    cardDisplayState: InfoSection.CardDisplayState.DEFAULT,
                    partnerIcon: exampleLogos[0],
                },
            },
            {
                Component: CardGrid,
                id: 'what-could-you-do-with-loan',
                props: {
                    heading: t('cardGrid.heading') as string,
                    subheading: t('cardGrid.subHeading') as string,
                    cardArray: cardGrid.cards.map(({ key, icon, href }) => (
                        <PreWrap key={key}>
                            <Card
                                backgroundColor="chill"
                                heading={t(`cardGrid.cards.${key}.heading`)}
                                bodyText={t(`cardGrid.cards.${key}.bodyText`)}
                                bodyHref={href}
                                key={key}
                                topIcon={icon}
                            />
                        </PreWrap>
                    )),
                },
            },
            {
                Component: CardWebsiteCta,
                id: 'start-comparing',
                section: {
                    dataId: 'start-comparing',
                    size: Section.Size.SMALL,
                    theme: 'dark',
                },
                props: {
                    heading: t('startComparing.heading') as string,
                    cta: handleSignupCTA(t('startComparing.cta.text')),
                    alignment: CardWebsiteCta.Alignment.LEFT,
                    theme: 'dark',
                },
            },
            {
                Component: TextBlock,
                id: 'loan-types',
                section: {
                    theme: 'dark',
                    backgroundColor: Section.BackgroundColor.MIDNIGHT,
                },
                props: {
                    heading: t('typesOfLoans.heading') as string,
                    description: t('typesOfLoans.description'),
                    types: typesOfLoans.map(({ key, href }: { key: string; href: string }) => ({
                        href,
                        heading: t(`typesOfLoans.types.${key}.heading`),
                        description: t(`typesOfLoans.types.${key}.description`),
                    })),
                },
            },
            {
                Component: ExplainerWithImage,
                id: 'explainer-with-image',
                props: {
                    ...explainer,
                    ...(t('explainer') as Record<string, string>),
                    body2: {
                        text: t('explainer.body2.text'),
                        href: explainerSection.href,
                    },
                    cards: explainerSection.cards.map(({ key, icon }: { key: string; icon: ReactElement }) => ({
                        ...(t(`explainer.cards.${key}`) as unknown as ComponentProps<typeof Card>),
                        cardType: Card.CardType.FLAT,
                        backgroundColor: 'chill',
                        topIcon: icon,
                    })),
                },
            },
            {
                Component: TextAndImage,
                id: 'what-is-a-loan-1',
                props: {
                    text: [t('whatsALoan')],
                    image: whatsLoan.image,
                } as ComponentProps<typeof TextAndImage>,
            },
            {
                Component: InfoSection,
                id: 'compare-tailored-offers-1',
                section: {
                    backgroundColor: Section.BackgroundColor.LIGHT,
                },
                props: {
                    cardLanguage: {
                        exampleCardTitle: t('representativeExample.cardLanguage.exampleCardTitle'),
                        exampleCardSubtitle: t('representativeExample.cardLanguage.exampleCardSubtitle'),
                        exampleCardMonthlyText: t('representativeExample.cardLanguage.exampleCardMonthlyText'),
                        exampleCardMonthlyAmount: t('representativeExample.cardLanguage.exampleCardMonthlyAmount'),
                        exampleCardTotalText: t('representativeExample.cardLanguage.exampleCardTotalText'),
                        exampleCardTotalAmount: t('representativeExample.cardLanguage.exampleCardTotalAmount'),
                        exampleCardDisclaimers: [t('representativeExample.cardLanguage.exampleCardDisclaimers')],
                    },
                    firstSectionText: (
                        <PreWrap>
                            <Text tag={Text.tags.H3} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                                {t('representativeExample.firstSectionText.heading') as string}
                            </Text>
                            <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.SMALL }}>
                                <Text.Body1>{t('representativeExample.firstSectionText.body') as string}</Text.Body1>
                            </Spacer>
                            <Text.Caption>
                                {t('representativeExample.firstSectionText.disclaimer') as string}
                            </Text.Caption>
                        </PreWrap>
                    ),
                    cardDisplayState: InfoSection.CardDisplayState.DEFAULT,
                    partnerIcon: exampleLogos[0],
                },
            },
            {
                Component: CardWebsiteCta,
                id: 'find-the-right-loan',
                section: {
                    dataId: 'find-the-right-loan',
                    theme: 'dark',
                    size: Section.Size.SMALL,
                },
                props: {
                    heading: t('findTheRightLoan.heading') as string,
                    bodyText: t('findTheRightLoan.bodyText'),
                    cta: handleSignupCTA(t('findTheRightLoan.cta.text')),
                    alignment: CardWebsiteCta.Alignment.LEFT,
                    theme: 'dark',
                },
            },
            {
                Component: FAQ,
                id: 'frequently-asked-questions',
                section: {
                    size: Section.Size.MEDIUM_LARGE,
                    dataId: 'faq',
                },
                props: {
                    heading: t('faq.heading'),
                    description: t('faq.description'),
                    faqs: faqs.map(({ name, href }) => ({
                        question: t(`faq.faqs.${name}.question`),
                        answer: [t(`faq.faqs.${name}.answer`)],
                        href,
                        dataId: name,
                    })),
                    cta: handleSignupCTA(t('faq.cta.text')),
                },
            },
        ];
    },
} as const;
