import React from 'react';
import LoanIcon from '@clearscore/rainbow.icons.loan';
import CoinsIcon from '@clearscore/rainbow.icons.coins';
import ReportIcon from '@clearscore/rainbow.icons.report';
import AccountIcon from '@clearscore/rainbow.icons.account';
import BankTickIcon from '@clearscore/rainbow.icons.bank-tick';
import BookOpenIcon from '@clearscore/rainbow.icons.book-open';
import HeartAccentIcon from '@clearscore/rainbow.icons.heart-accent';
import HeartIcon from '@clearscore/rainbow.icons.heart';
import HouseAccentIcon from '@clearscore/rainbow.icons.house-accent';
import HouseIcon from '@clearscore/rainbow.icons.house';
import ScoreChangeIcon from '@clearscore/rainbow.icons.score-change';
import CarSportIcon from '@clearscore/rainbow.icons.car-sport';
import CarSportAccentIcon from '@clearscore/rainbow.icons.car-sport-accent';
import PalmTreeAccentIcon from '@clearscore/rainbow.icons.palm-tree-accent';
import CreditCardAccentIcon from '@clearscore/rainbow.icons.credit-card-accent';
import CreditCardMultipleIcon from '@clearscore/rainbow.icons.credit-card-multiple';

export const PAGE_NAMESPACE = 'loans-landing-09-22';
export const ICONS = {
    loan: <LoanIcon />,
    account: <AccountIcon />,
    bankTick: <BankTickIcon height={32} style={{ color: 'var(--rnb-colour-chill)' }} />,
    coins: <CoinsIcon height={32} style={{ color: 'var(--rnb-colour-chill)' }} />,
    report: <ReportIcon height={32} style={{ color: 'var(--rnb-colour-chill)' }} />,
    creditCardAccent: <CreditCardAccentIcon height={64} />,
    creditCardMultiple: <CreditCardMultipleIcon height={32} />,
    heartAccent: <HeartAccentIcon height={64} />,
    heart: <HeartIcon height={32} />,
    houseAccent: <HouseAccentIcon height={64} />,
    house: <HouseIcon height={32} />,
    carSportAccent: <CarSportAccentIcon height={64} />,
    carSport: <CarSportIcon height={32} />,
    palmTreeAccent: <PalmTreeAccentIcon height={64} />,
    scoreChange: <ScoreChangeIcon height={64} />,
    scoreChangeSmall: <ScoreChangeIcon height={32} />,
    bookOpen: <BookOpenIcon height={32} />,
} as const;
