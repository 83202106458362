import React from 'react';
import Spacer from '@clearscore/ui.rainbow.spacer';
import CardRepExample from '@clearscore/shared.card-representative-example';
import cx from 'classnames';
import type { IGatsbyImageData } from 'gatsby-plugin-image';

import styles from './section-information-with-example.module.css';

const Theme = {
    DARK: 'dark',
    LIGHT: 'light',
} as const;

export interface CardLangProps {
    exampleCardTitle: string;
    exampleCardSubtitle: string;
    exampleCardMonthlyText: string;
    exampleCardMonthlyAmount: string;
    exampleCardTotalText: string;
    exampleCardTotalAmount: string;
    exampleCardDisclaimers?: string[];
}

export interface SectionInformationWithExampleProps {
    firstSectionText?: React.ReactNode;
    secondSectionText?: React.ReactNode;
    cardLanguage: CardLangProps;
    theme?: (typeof Theme)[keyof typeof Theme];
    partnerIcon?: { alt: string; image: IGatsbyImageData };
    isReversed?: boolean;
    cardDisplayState?: (typeof CardRepExample.CardDisplayState)[keyof typeof CardRepExample.CardDisplayState];
}

export const SectionInformationWithExample = ({
    firstSectionText,
    secondSectionText,
    cardLanguage,
    theme = Theme.LIGHT,
    partnerIcon,
    isReversed = false,
    cardDisplayState,
}: SectionInformationWithExampleProps): React.ReactElement => (
    <article
        className={cx(styles.sectionInformationWithExample, {
            [styles.isReversed]: isReversed,
            [styles.isDark]: theme === 'dark',
        })}
    >
        <div className={styles.flex1}>{firstSectionText}</div>
        <div className={styles.flex1}>
            {secondSectionText}
            <Spacer small={{ top: Spacer.spacings.LARGE }} all={{ top: Spacer.spacings.MEDIUM }}>
                <CardRepExample
                    title={cardLanguage.exampleCardTitle}
                    description={cardLanguage.exampleCardSubtitle}
                    monthlyPrice={cardLanguage.exampleCardMonthlyAmount}
                    monthlyPriceDescription={cardLanguage.exampleCardMonthlyText}
                    totalPrice={cardLanguage.exampleCardTotalAmount}
                    totalPriceDescription={cardLanguage.exampleCardTotalText}
                    disclaimers={cardLanguage.exampleCardDisclaimers}
                    partnerIcon={partnerIcon}
                    cardDisplayState={cardDisplayState}
                />
            </Spacer>
        </div>
    </article>
);

SectionInformationWithExample.Theme = Theme;
SectionInformationWithExample.CardDisplayState = CardRepExample.CardDisplayState;
export default SectionInformationWithExample;
